





















































import NsfwHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/NsfwHandlerModule/NsfwHandlerModuleBuilder'
import { InputSetups } from '@/mixins/input-setups'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'
import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import { PunishActionTypesEnum } from '@/includes/logic/Modules/models/PunishActions/types'

import { SelectOption } from 'piramis-base-components/src/logic/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    PunishActionSetting,
    SelectInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
})
export default class NsfwHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper) {
  @VModel() module!: NsfwHandlerModuleBuilder

  get punishActionTypes(): Array<PunishActionTypesEnum> {
    return [
      PunishActionTypesEnum.Ban,
      PunishActionTypesEnum.Kick,
      PunishActionTypesEnum.Mute,
      PunishActionTypesEnum.MuteMedia,
      PunishActionTypesEnum.Notify,
      PunishActionTypesEnum.Incremental
    ]
  }

  get tariffTag() {
    return this.module.tariffTags()
  }

  nsfwBanTypeOptions: Array<SelectOption> = [
    { label: this.$t('nsfw_ban_type_none'), value: 'None' },
    { label: this.$t('nsfw_ban_type_ban'), value: 'Ban' },
    { label: this.$t('nsfw_ban_type_kick'), value: 'Kick' },
    { label: this.$t('nsfw_ban_type_mute'), value: 'Mute' },
    { label: this.$t('nsfw_ban_type_mute_media'), value: 'MuteMedia' },
  ]

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    }
  }}
